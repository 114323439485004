@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-family: 'Oswald', sans-serif;
}

.wrapper{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: .95;
  background: #212020;
  clip-path: circle(25px at calc(100% - 45px) 45px);
  transition: all 0.3s ease-in-out;
  z-index: 2;
}

#active:checked ~ .wrapper{
  clip-path: circle(75%);
}

.menu-btn{
  position: absolute;
  z-index: 3;
  right: 20px;
  top: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  color: #000000;
  cursor: pointer;
  background: #ffffff;
  transition: all 0.3s ease-in-out;
}

#active:checked ~ .menu-btn{
  color: #000000;
}

#active:checked ~ .menu-btn i:before{
  content: "\f00d";
}

.wrapper ul{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
}

.wrapper ul li{
  margin: 15px 0;
}

.wrapper ul li a{
  color: none;
  text-decoration: none;
  font-size: 30px;
  font-weight: 500;
  padding: 5px 30px;
  color: #fff;
  border-radius: 50px;
  background: #000;
  position: relative;
  line-height: 50px;
  transition: all 0.3s ease;
}

.wrapper ul li a:after{
  position: absolute;
  content: "";
  background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);
  width: 104%;
  height: 110%;
  left: -2%;
  top: -5%; 
  border-radius: 50px;
  transform: scaleY(0);
  z-index: -1;
  animation: rotate 1.5s linear infinite;
  transition: transform 0.3s ease;
}

.wrapper ul li a:hover:after{
  transform: scaleY(1);
}

.wrapper ul li a:hover{
  color: #8729da;
}

input[type="checkbox"]{
  display: none;
}

@keyframes rotate {
  0%{
    filter: hue-rotate(0deg);
  }
  100%{
    filter: hue-rotate(360deg);
  }
}