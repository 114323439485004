.contact-container {
  height: 100vh;
  background-color: black;
  overflow: scroll;
  animation: fadeIn 1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header-text {
  font-family: 'Lato', sans-serif;
  text-align: center;
  margin-top: 40vh;
  font-size: 60px;
  z-index: 1;
  color: rgb(185, 62, 62);
}

.cont-buttons-container {
  position: fixed;
  top: 20px;
  left: 10px;
  z-index: 2;
}

.cont-home-button,
.cont-portfolio-button {
  font-size: 30px;
  margin: 0 10px;
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  z-index: 3;
}

.cont-home-button:hover {
  background-color: rgb(52, 159, 209);
}

.cont-portfolio-button:hover {
  background-color: green;
}

form {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

form div {
  margin-bottom: 15px;
  width: 60%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
}

input[type="text"], input[type="email"], textarea {
  width: 100%;
  padding: 10px;
  border: 2px solid transparent;
  background-color: transparent;
  border-color: #00008B;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease;
  color: #fff;
}

input[type="text"]:focus, input[type="email"]:focus, textarea:focus {
  background-color: #fff;
  color: gray;
}

textarea {
  resize: vertical;
}

button[type="submit"] {
  padding: 10px 20px;
  width: 20%;
  border: none;
  border-radius: 5px;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #218838;
}