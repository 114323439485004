body {
  background-color: black;
}

/* Hide the scrollbar track */
::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

/* Hide the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make the thumb transparent */
}

.App {
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: fixed;
  user-select: none;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.line {
  position: absolute;
  height: .5px;
  background-color: rgba(128, 128, 128, 0.5);
  transform-origin: left top;
}

.hide-background-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.5s ease;
  z-index: 1;
}

.hide-background-button:hover {
  background-color: #8e15ca;
}

@media (max-width: 800px) {
  .mobile {
    background-color: black;
  }
}