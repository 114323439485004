@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.Home-container {
  background-color: black;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Home-info {
  margin-left: 50px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.profile-pic {
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-img {
  margin-right: 100px;
  margin-top: 80px;
  height: 500px;
}

@keyframes tilt-n-move-shaking {
  80% { transform: translate(0, 0) rotate(0deg); }
  85% { transform: translate(5px, 5px) rotate(5deg); }
  90% { transform: translate(0, 0) rotate(0eg); }
  95% { transform: translate(-5px, 5px) rotate(-5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

.name-text {
  font-family: 'Lato', sans-serif;
  margin-top: 100px;
  left: 50%;
  font-size: 80px;
  color: rgb(232, 226, 226);
  z-index: 1;
}

.my-titles {
  left: 50%;
  max-width: 100%;
  font-size: 50px;
  color: white; 
  font-weight: bold;
  z-index: 1;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  20%, 80% {
    opacity: 1;
  }
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.portfolio-button,
.contact-button {
  font-size: 30px;
  margin: 0 30px;
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  z-index: 1;
}

.portfolio-button:hover {
  background-color: green;
}

.contact-button:hover {
  background-color: darkorange;
}

.logos-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.linkedin-logo {
  margin: 0 40px;
  height: 100px;
  cursor: pointer;
  animation: tilt-n-move-shaking 2s ease-in-out infinite;
}

.github-logo {
  margin: 0 40px;
  height: 100px;
  cursor: pointer;
  animation: tilt-n-move-shaking 2s ease-in-out 1s infinite;
}

.white-bar {
  width: 70%;
  height: 8px;
  background-color: rgb(209, 203, 203);
}

@media (max-width: 800px) {
  .Home-container {
    flex-direction: column;
  }

  .profile-img {
    height: 300px;
    margin-top: 20px;
    margin-left: 130px;
  }

  .Home-info {
    width: 300px;
  }

  .name-text {
    font-size: 40px;
  }

  .my-titles {
    font-size: 24px;
  }

  .portfolio-button,
  .contact-button {
  font-size: 20px;
  margin: 0 5px;
}

}

